import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          home: {
            joinRoom: 'Join',
            createRoom: 'Create',
            confirm: 'Confirm',
            typeName: 'Type your name',
            typeRoomId: 'Type the room ID',
            onlineModeExplanation: "Ready to test your skills against players from around the globe? Jump into Online Mode and see what you're made of!",
            partyModeExplanation: 'Feeling social? Dive into Party Mode and turn your next gathering into a blast!',
            quickGame: 'Quick game',
            partyMode: 'Party Mode',
            onlineMode: 'Online Mode',
            noOfActivePlayersMessagePt1: 'There are currently',
            noOfActivePlayersMessagePt2: 'active players in',
            noOfActivePlayersMessagePt3: 'rooms',
            changeLanguage: 'PT 🇧🇷'
          },
          waitingRoom: {
            room: 'Room',
            helperText: 'Players should enter the room with the QR Code or with the room ID',
            helperTextForOnlineMode: 'Players may enter this room by using the room ID or via quick game',
            playersConnected: 'Players connected',
            qrCodeHelperText: 'Scan the code with your smartphone!',
            startGameShort: 'Start',
            startGame: 'Start game!',
            unsufficientNoOfPlayers: 'It is not possible to start the game with no players.',
            onlyHostMayStart: 'Only the host may start the game'
          },
          playerScreen: {
            gameQuestion: 'Which came first?',
            seePrevious: 'See previous',
            seeNext: 'See next',
            waitForYourTurn: 'Wait for your turn to play',
            connectingToServer: 'Connecting to server...',
            wrongGuess: 'Wrong guess. Too bad!',
            rightGuess: 'Correct!',
            youSurvivedUntil: 'You survived until round',
            noNextCards: 'There are no next cards',
            noPreviousCards: 'There are no previous cards'
          },
          table: {
            timelineTitle: 'Difficulty',
            playersListTitle: 'Players',
            room: 'Room',
            gameQuestion: 'Which came first?',
            gameCompleted: 'Congratulations! You played all cards!',
            tutorialStep1: 'This is the timeline. Your goal is to add new events to it in the correct chronological order.',
            tutorialStep2: "This is where you'll make your move. You'll always have to choose which of two events happened first.",
            tutorialStep2PartyMode: "In your turn, you'll make choices of which event happened first out of two.",
            tutorialStep2bPartyMode: "You will make these choice in your own device.",
            tutorialStep3: 'This section shows the players currently in the room. Each player can make up to five mistakes before being eliminated from the game.',
            tutorialStep4: 'If you get disconnected from the game, you can reconnect using this room ID and your player name.',
            tutorialStep5: 'Now, click on the event that you think happened first!',
            skip: 'Skip',
            next: 'Next',
            last: 'Close',
            back: 'Back'
          },
          tableNotifications: {
            timelimit: 'Time limit',
            seconds: 'seconds',
            theCard: 'The card',
            wasAddedToTheTimeline: 'was added to the timeline',
            scored: 'scored',
            missed: 'missed',
            awaiting: 'Awaiting',
            reconnectToTable: 'reconnect to table',
            timelimitPenaltyApplied: 'ran out of time and lost a life',
            generatingTimeline: 'Generating a new timeline'
          },
          errorMessages: {
            playerNameAlreadyExists: 'Player name already exists in this room. Try another name',
            gameHasAlreadyStarted: 'Game already started. New players cannot join',
            tableIsFull: 'The room is full',
            errorConnectingToServer: "Can't connect to server"
          },
          podiumStep: {
            rounds: 'rodada(s)',
            difficulty: 'dificuldade'
          },
          playerItem: {
            yourTurn: 'your turn',
            youreOut: "you're out"
          }
        }
      },
      pt: {
        translation: {
          home: {
            joinRoom: 'Entrar',
            createRoom: 'Criar',
            confirm: 'Confirmar',
            typeName: 'Digite seu nome',
            typeRoomId: 'Digite o ID da sala',
            onlineModeExplanation: "Preparado para testar suas habilidades contra jogadores de todo o mundo? Entre no Modo Online e mostre do que é capaz!",
            partyModeExplanation: 'Reúna a galera e prepare-se para criar momentos inesquecíveis com o Modo Party! Transforme seu encontro em uma explosão de risadas e competitividade!',
            quickGame: 'Jogo rápido',
            partyMode: 'Modo Party',
            onlineMode: 'Modo Online',
            noOfActivePlayersMessagePt1: 'Há',
            noOfActivePlayersMessagePt2: 'jogadores ativos em',
            noOfActivePlayersMessagePt3: 'salas',
            changeLanguage: 'EN 🇬🇧'
          },
          waitingRoom: {
            room: 'Sala',
            helperText: 'Jogadores devem entrar na sala utilizando o QR Code abaixo ou o ID da sala',
            helperTextForOnlineMode: 'Jogadores podem entrar na sala com o ID da sala ou via jogo rápido',
            playersConnected: 'Jogadores na sala',
            qrCodeHelperText: 'Escaneie o código com seu celular!',
            startGameShort: 'Iniciar',
            startGame: 'Iniciar partida!',
            unsufficientNoOfPlayers: 'Não é possível iniciar sem jogadores na sala.',
            onlyHostMayStart: 'Apenas o anfitrião pode iniciar o jogo'
          },
          playerScreen: {
            gameQuestion: 'O que veio antes?',
            seePrevious: 'Ver anteriores',
            seeNext: 'Ver próximas',
            waitForYourTurn: 'Aguarde a sua vez de jogar',
            connectingToServer: 'Conectando ao servidor...',
            wrongGuess: 'Que pena, você errou',
            rightGuess: 'Correto!',
            youSurvivedUntil: 'Você sobreviveu até a rodada',
            noNextCards: 'Não há cartas posteriores',
            noPreviousCards: 'Não há cartas anteriores'
          },
          table: {
            timelineTitle: 'Dificuldade',
            playersListTitle: 'Jogadores',
            room: 'Sala',
            gameQuestion: 'O que veio antes?',
            gameCompleted: 'Parabéns! Você acertou todas as cartas do jogo!',
            tutorialStep1: 'Essa é a linha do tempo. Seu objetivo é adicionar a ela novos eventos, na ordem cronológica correta.',
            tutorialStep2: 'Aqui você fará a sua jogada. Você sempre terá de escolher qual veio primeiro entre dois eventos.',
            tutorialStep2PartyMode: "Essas são as opções de jogada do jogador da vez, que deve escolher qual veio primeiro entre dois eventos.",
            tutorialStep2bPartyMode: "O jogador da vez deve fazer a escolha do evento em seu próprio dispositivo.",
            tutorialStep3: 'Nessa seção estão os jogadores presentes na sala. Cada jogador pode errar até cinco vezes antes de ser eliminado da partida.',
            tutorialStep4: 'Caso você seja desconectado da partida, você pode reconectar usando este ID de sala e seu nome de jogador.',
            tutorialStep5: 'Agora, clique no evento que você acredita ter acontecido primeiro!',
            skip: 'Pular',
            next: 'Próximo',
            last: 'Fechar',
            back: 'Voltar'
          },
          tableNotifications: {
            timelimit: 'Tempo limite',
            seconds: 'segundos',
            theCard: 'A carta',
            wasAddedToTheTimeline: 'foi adicionada à linha do tempo',
            scored: 'acertou',
            missed: 'errou',
            awaiting: 'Aguardando',
            reconnectToTable: 'reconectar-se à mesa',
            timelimitPenaltyApplied: 'não jogou no tempo limite e perdeu uma vida',
            generatingTimeline: 'Gerando nova linha do tempo'
          },
          errorMessages: {
            playerNameAlreadyExists: 'Nome de jogador já existente na sala. Tente novamente com outro nome',
            gameHasAlreadyStarted: 'Partida em andamento. Novos jogadores não podem mais ingressar',
            tableIsFull: 'A sala está cheia',
            errorConnectingToServer: 'Erro em conectar com o servidor'
          },
          podiumStep: {
            rounds: 'round(s)',
            difficulty: 'difficulty'
          },
          playerItem: {
            yourTurn: 'sua vez',
            youreOut: "eliminado"
          }
        }
      }
    }
  });

export default i18n;