import { Card, CardContent, Typography, CardMedia, Grow, CardActionArea } from "@mui/material";
import { useEffect, useState } from "react";
import i18n from "../i18n";

import { BACKEND_BASE_URL, CARD_YEAR_FONT_SIZE_INCREMENT_IN_REM } from "../constants";

export function CardOnPlayerView(props: any) {
  const userLanguage = i18n.language;
  const [cardImage, setCardImage] = useState(require(`../assets/loadingCardImage.jpg`));

  useEffect(() => {
    const abortController = new AbortController();
    async function loadImage(cardId: string) {
      const imageUrl = `${BACKEND_BASE_URL}/static/${cardId}.jpg`;
      const response = await fetch(imageUrl, {signal: abortController.signal});
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      setCardImage(url);
    }
    if (props.cardId) loadImage(props.cardId);
    return () => {
      try {
        abortController.abort();
      } catch (e) {
        // ignoring deliberately
        console.log(e);
      }
    }
  }, [props.cardId])

  const [shouldCardBeVisible, setShouldCardBeVisible] = useState(false);
  const [cardYear, setCardYear] = useState('');

  const styles = {
    portraitCard: {
      width: '37vw',
      height: '32vh',
    },
    portraitImage: {
      width: '40vw',
      height: '27vh',
    },
    landscapeCard: {
      width: '15vw',
      height: '35vh',
    },
    landscapeImage: {
      width: '15.5vw',
      height: '27vh',
    },
  }

  useEffect(() => {
    if (props.cardId) {
      setShouldCardBeVisible(true)
    } else {
      setShouldCardBeVisible(false)
    }
  }, [props.cardId])

  useEffect(() => {
    if (props.shouldHideYear) setCardYear('?')
    else {
      const year = (Math.abs(props.year)).toString() + (props.year < 0 ? 'AC' : '')
      setCardYear(year)
    }
  }, [props.shouldHideYear, props.year])

  const fontSizeInRem = 1.25
  const yearFontSizeInRem = fontSizeInRem * CARD_YEAR_FONT_SIZE_INCREMENT_IN_REM
  let portrait = window.matchMedia("(orientation: portrait)").matches;
  window.matchMedia("(orientation: portrait)").addEventListener("change", e => portrait = e.matches);

  return (
    <Grow in={shouldCardBeVisible}>
      <Card>
        <CardActionArea onClick={() => props.onClick()}>
        <CardContent 
          className="CardOnPlayerView"
          style={portrait ? styles.portraitCard : styles.landscapeCard}
        >
          <Typography gutterBottom fontSize={`${yearFontSizeInRem}rem`}>
            {cardYear}
          </Typography>
          <CardMedia
            style={portrait ? styles.portraitImage : styles.landscapeImage}
            image={cardImage}
          />
          <Typography color="text.secondary" fontSize={`${fontSizeInRem}rem`}>
          {userLanguage === 'pt' ? props.namePT : props.nameEN}
          </Typography>
        </CardContent>
        </CardActionArea>
      </Card>
    </Grow>
  )
}