import { Card, Typography, CardMedia, Grow } from "@mui/material";
import { useEffect, useState } from "react";
import i18n from "../i18n";

import { BACKEND_BASE_URL } from "../constants";

const backOfCardImage = require(`../assets/backOfCard.png`);
const loadingCardImage = require(`../assets/loadingCardImage.jpg`);

export function ShowcasedPlayerCard(props: any) {
  const [cardYear, setCardYear] = useState('');
  const [cardImage, setCardImage] = useState(backOfCardImage);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    async function loadImage(cardId: string) {
      setIsLoading(true);
      const imageUrl = `${BACKEND_BASE_URL}/static/${cardId}.jpg`;
      try {
        const response = await fetch(imageUrl, {signal: abortController.signal});
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setCardImage(url);
      } catch (error) {
        console.error("Error while fetching image", error)
      } finally {
        setIsLoading(false);
      }
    }
    if (props.cardId) {
      loadImage(props.cardId);
    }
    return () => {
      abortController.abort();
    }
  }, [props.cardId])
  
  const userLanguage = i18n.language;

  const cardYearFontSizeCustomIncrement = 1.2
  const fontSize = 'max(1.5vmin, 10px)'
  const yearFontSize = `max(${1.5 * cardYearFontSizeCustomIncrement}vmin, ${10 * cardYearFontSizeCustomIncrement}px)`

  const handleOnClick = () => {
    const beforePivotCard = !props.isPivot
    if (props.isClickable) props.onClick(beforePivotCard)
  }

  useEffect(() => {
    if (props.shouldHideYear) setCardYear('?')
    else {
      const year = (Math.abs(props.year)).toString() + (props.year < 0 ? 'AC' : '')
      setCardYear(year)
    }
  }, [props.shouldHideYear, props.year])

  return (
    <>
    {props.cardId ? (
    <Grow in={true}>
      <Card
        onClick={handleOnClick}
        sx={{
          width: '30%',
          maxHeight: '27vh',
          aspectRatio: '2 / 3',
          backgroundColor: 'rgb(205,205,205)',
          ":hover": { 
            width: props.isClickable ? '29.5%' : '30%',
            color: props.isClickable ? 'white' : 'default',
          }
        }}
        className="ShowcasedPlayerCard"
      >
        <Typography gutterBottom fontSize={yearFontSize} fontWeight={'bold'}>
          {cardYear}
        </Typography>
        <CardMedia
          sx={{
            mb: '0.5vh',
            width: '91%', 
            aspectRatio: '17 / 18', 
            maxHeight: '20vh'}}
          image={isLoading ? loadingCardImage : cardImage}
        />
        <Typography fontSize={fontSize} fontWeight={'bold'}>
          {userLanguage === 'pt' ? props.namePT : props.nameEN}
        </Typography>
      </Card>
    </Grow>
    ) : (
      <img alt='unknown card' src={backOfCardImage} id='loadingShowcasedPlayerCard'/>
    )}
    </>
  )
}