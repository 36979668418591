import { Card, CardContent, Typography, CardMedia } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";

import { BACKEND_BASE_URL, CARD_YEAR_FONT_SIZE_INCREMENT_IN_EM, TIME_FOR_CARD_FADE_IN_MILLISECONDS } from "../constants";
import i18n from "../i18n";

export function CardOnTimeline(props: any) {
  const userLanguage = i18n.language;
  const [cardImage, setCardImage] = useState(require(`../assets/loadingCardImage.jpg`));

  useEffect(() => {
    async function loadImage(cardId: string) {
      const imageUrl = `${BACKEND_BASE_URL}/static/${cardId}.jpg`;
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      setCardImage(url);
    }
    loadImage(props.cardId);
  }, [props.cardId])

  const timeUntilVisible =
    (props.idx !== undefined && props.renderWithDelay) ?
    (TIME_FOR_CARD_FADE_IN_MILLISECONDS/1000) * props.idx : 0

  const transitionDurationInSeconds = 1

  const fontSizeInEm = 0.45
  const yearFontSizeInEm = fontSizeInEm * CARD_YEAR_FONT_SIZE_INCREMENT_IN_EM

  const cardWidth = 12.5;
  const highlightedCardWidth = 15.5;
  
  const cardMaxHeight = 31.5;
  const highlightedCardMaxHeight = cardMaxHeight * 1.14;

  const cardAspectRatio = 2/3;

  const imageAspectRatio = 17/18;
  const imageMaxHeight = cardMaxHeight - 10.5;
  const highlightedImageMaxHeight = highlightedCardMaxHeight - 6.5;

  return (
  <motion.div
    style={{
      aspectRatio: `${cardAspectRatio}`,
      maxHeight: `${cardMaxHeight}vh`,
      width: `min(${cardWidth}vw, ${cardMaxHeight * cardAspectRatio}vh)`,
      boxShadow: "0",
      borderRadius: '12px',
    }}
    initial="hidden"
    animate="visible"
    variants={{
      visible: {
        opacity: props.isActive ? 1 : 0.55,
        maxHeight: props.shouldHighlight ? `${highlightedCardMaxHeight}vh` : `${cardMaxHeight}vh`,
        width: props.shouldHighlight ? 
          `min(${highlightedCardWidth}vw, ${highlightedCardMaxHeight * cardAspectRatio}vh)` :
          `min(${cardWidth}vw, ${cardMaxHeight * cardAspectRatio}vh)`,
        boxShadow: 
          props.shouldHighlight ? 
          'min(5px, .8vmin) min(5px, .8vmin) black' :
          '0',
        transition: {
          delay: timeUntilVisible,
          duration: transitionDurationInSeconds
        }
      },
      hidden: { opacity: 0 }
    }}
  >
    <Card 
      className="CardOnTimeline ColumnFlexWithCenteredItems"
      sx={{width: '100%', height: '100%'}}>
      <CardContent>
        <Typography gutterBottom fontSize={`${yearFontSizeInEm}em`}>
          {props.shouldHighlight ? (
            <strong>⭐{Math.abs(props.year)} {props.year < 0 ? 'AC' : ''}⭐</strong>
          ) : (
            <>{Math.abs(props.year)} {props.year < 0 ? 'AC' : ''}</>
          )}
        </Typography>

        <motion.div
          style={{
            width: `min(${cardWidth - 1}vw, ${imageMaxHeight * imageAspectRatio}vh)`,
            filter: 'none',
            marginBottom: '0.5vh',
            aspectRatio: `${imageAspectRatio}`, 
            maxHeight: `${imageMaxHeight}vh`
          }}
          initial="hidden"
          animate="visible"
          variants={{
            visible: {
              maxHeight: props.shouldHighlight ? `${highlightedImageMaxHeight}vh` : `${imageMaxHeight}vh`,
              opacity: props.isActive ? 1 : 0.75,
              width: props.shouldHighlight ?
                `min(${highlightedCardWidth - 1}vw, ${highlightedCardMaxHeight * cardAspectRatio - 1}vh)` :
                `min(${cardWidth - 1}vw, ${imageMaxHeight * imageAspectRatio}vh)`,
              transition: {
                delay: timeUntilVisible,
                duration: transitionDurationInSeconds
              }
            },
            hidden: { opacity: 0 }
          }}
        >
        <CardMedia
          sx={{width: '100%', height: '100%'}}
          image={cardImage}
        />
        </motion.div>

        <Typography color="text.secondary" fontSize={`${fontSizeInEm}em`}>
          {userLanguage === 'pt' ? props.namePT : props.nameEN}
        </Typography>
      </CardContent>
    </Card>
  </motion.div>
  )
}